@import "./color.scss";
@import "./var.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap');
@import url('https://use.typekit.net/dil6xhk.css');
@import url('https://use.typekit.net/wtg2jdl.css');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #DFDED9;
    font-family: var(--font-family);
    overflow: -moz-scrollbars-none; /* Firefox 只有垂直滾動條的隱藏屬性 */
    overflow-y: scroll; /* 強制使內容可垂直滾動 */
    // overflow: hidden;
}

/* 針對 Chrome、Safari、Edge 隱藏滾動條 */
body::-webkit-scrollbar {
    display: none; /* 隱藏滾動條 */
}


h1 {
    // font-size: 64px;
    font-size: var(--h1-font-size);
    font-weight: var(--h1-font-weight);
    color: white;
    font-variation-settings: var(--h1-font-weight);
}


h4 {
    font-size: var(--h4-font-size);
    font-variation-settings: var(--h5-font-weight);
    letter-spacing: 1px;
}

h5 {
    font-size: var(--h5-font-size);
    font-variation-settings: var(--h5-font-weight);
    letter-spacing: 0.6px;
}


@mixin flex($justify: center, $align: center) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}

@mixin grid($justify: center, $align: center) {
    display: grid;
    justify-content: $justify;
    align-items: $align;
}

.background-slide {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $bg;
    transform: translateY(100%); /* 初始狀態在屏幕下方 */
    animation: slideIn 1.5s cubic-bezier(0.7, 0.05, 0.01, 0.99) forwards; /* 1.5秒內完成動畫 */
    z-index: -1;
    animation-delay: 1s;
}

.initial-text {
    text-align: center;
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    font-weight: 500;
    width: 100%;
    position: fixed;
    color: $bg;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    opacity: 0; /* 初始状态不可见 */
    z-index: -2; /* 確保文字位於背景之上 */
    animation: fadeIn 0.5s ease-in-out forwards, fadeOut 0.3s ease-in-out 1.3s forwards; /* 先淡入，再淡出 */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        filter: blur(10px);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


@keyframes slideIn {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

.header {
    opacity: 0;
    transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
    @include flex(space-between);
    gap: 64px;
    padding: 14px 48px 12px 48px;
    min-width: 30%;
    position: fixed;
    width: 100%;
    z-index: 10;
    // background-color: $bg;
    border-bottom: 1px solid $gray-3;
    background: rgba($color: $bg, $alpha: 0.9);
    backdrop-filter: blur(16px);

    &-button {
        transition: color .4s;
        color: $gray;
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-family: "Noto Sans TC", sans-serif;
        cursor: pointer;
        padding: 4px 14px;
        z-index: 50;
        &:hover {
            color: white;
        }
    }
}

.header.hidden {
    transform: translateY(-100%); // 將 Header 往上移出視窗
}

.content {
    flex: 1;
}

.header.animate  {
    opacity: 1;
    animation: fadeIn 0.5s ease-in-out forwards;
}

// .content.show .header {
//     transition: fadeIn 0.5s ease-in-out forwards;
//     transition-delay: 2s; /* 增加延遲確保背景動畫結束後顯示 Header */
//     opacity: 1;
// }


.nav-logo {
    @include flex;
    img {
        width: 48px;
    }
    
}

.link {
    &-group {
        @include flex(space-between);
        // width: 320px;
        column-gap: 16px;
        border: 1px solid $gray-3;
        border-radius: 100px;
        padding: 6px;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(16px);
    }
    &-container {
        @include flex;
        column-gap: 16px;
    }
}


// .button-style {
//     position: relative;
//     overflow: hidden;
//     text-align: center;
//     border-radius: 100px;
//     min-width: 96px;
//     touch-action: manipulation;
//     z-index: 1;
//     transition: color 0.3s ease, border-bottom 0.3s ease; /* 添加過渡效果 */

//     &:hover {
//         color: black; /* 當鼠標懸停時改變字體顏色 */
//     }

//     &.active {
//         color: black;
//         background-color: white;/* 激活時添加底部邊框 */
//     }
// }


// .button-style:after {
//     content: "";
//     position: absolute;
//     bottom: -50%;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: white;

//     transform-origin: bottom center;
//     transition: transform 900ms cubic-bezier(0.48, 0, 0.12, 1);
//     transform: skewY(9.3deg) scaleY(0);
//     z-index: -1;
// }

// .button-style:hover:after {
//     transform-origin: bottom center;
//     transform: skewY(9.3deg) scaleY(2);
// }



.spotlight-container {
    cursor: default;
    @include flex(center, flex-start);
    flex-direction: column;
    max-width: 1440px;
    padding-top: 140px;
    padding-left: 48px;
    padding-right: 48px;
    text-align: left;
    margin: auto;
    h2 {
        line-height: var(--h2-line-height);
        letter-spacing: 1px;
        color: $gray;
        font-size: var(--h2-font-size);
        font-variation-settings: var(--h2-font-weight);
    }
}

.banner {
    margin-bottom: 80px;
}

.wrapper {
    &-title {
        // overflow: hidden; /* 確保標題超出部分不顯示 */
        // height: 80px; /* 設置一個合適的高度，與標題高度一致 */
        margin-bottom: 20px;
    }
    // &-text {
    //     overflow: hidden; /* 確保標題超出部分不顯示 */
    //     height: 68px; /* 設置一個合適的高度，與標題高度一致 */
    // }
    &-hint {
        display: inline-block;
        margin-top: 80px;
        cursor: pointer;
        
    }
}

.title, .text, .hint-link, .ad-column1 {
    opacity: 0;
}

.hint-link {
    display: inline-block; // 確保 Link 包含 h5，並且可以使用 transform
}

.hint {
    padding: 8px 12px 8px 16px;
    color: $gold;
    border: 1px solid rgba($color: #dac5a7, $alpha: 0.4);
    border-radius: 1px;
    background-color: $bg-2;
    // line-height: 36px;

    transition: transform 0.3s ease, box-shadow 0.3s ease; // 平滑過渡效果
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);

    &:hover {
        transform: translateY(-4px); // 向上移動 10px
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); // 在懸停時加強陰影效果
    }
}

.portfolio {
    opacity: 0;
    width: 100%;
    margin-bottom: 80px;
    > h5 {
        color: $gold;
        margin-top: 60px;
    }
}

.card {
    &:first-child {
        grid-column: span 2;
    }
    > h5 {
        color: $gold;
        margin-top: 24px;
    }
    &-group {
        width: 100%;
        @include grid(flex-start, center);
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        
        column-gap: 60px;
        padding-bottom: 64px;
        border-bottom: 1px solid $gray-3;
        margin-top: 20px;
    }
    &-title {
        font-size: 48px;
        color: white;
        font-variation-settings: var(--h4-font-weight);
        margin-bottom: 8px;
    }
    &-content {
        font-size: var(--h4-font-size);
        color: $gray;
        font-variation-settings: var(--h6-font-weight);
        &_more {
            font-size: var(--h5-font-size);
            letter-spacing: 0.3px;
            color: $gray-2;
            font-variation-settings: var(--h6-font-weight);
            margin-top: 16px;
        }
    }
    &-type {
        font-size: var(--h5-font-size);
        letter-spacing: 0.3px;
        color: $gray-2;
        font-variation-settings: var(--h6-font-weight);
        margin-top: 8px;
    }
    &-img {
        opacity: 0;
        margin-top: 60px;
        img {
            width: 100%;
            border-radius: 12px;
            margin-bottom: 40px;
            border: 1px solid $gray-3;
        }
        section {
            margin-bottom: 80px;
        } 
    }
    &-wrapper {
        opacity: 0;
        width: 100%;
    }
}

.title.animate, .text.animate, .hint-link.animate, .ad-column1.animate, .footer.animate, .card-wrapper.animate  {
    opacity: 1;
    animation: fadeInUp 1.5s cubic-bezier(0.4, 0.01, 0.01, 1) forwards;
}

.portfolio.animate{
    animation: blurToClear 0.5s ease-out forwards;
    animation-delay: 0.5s;
}



@keyframes blurToClear {
    0% {
        transform: translateY(20px);
        filter: blur(10px); /* 初始模糊程度 */
        opacity: 0;         /* 初始透明度 */
    }
    100% {
        transform: translateY(0);
        filter: blur(0);    /* 最終清晰 */
        opacity: 1;         /* 最終完全可見 */
    }
}


.card-img {
    animation: blurToClear2 1s ease-out forwards;
    animation-delay: 2.5s;
}


@keyframes blurToClear2 {
    0% {
        transform: translateY(20px);
        filter: blur(20px); /* 初始模糊程度 */
        opacity: 0;         /* 初始透明度 */
    }
    100% {
        transform: translateY(0px);
        filter: blur(0);    /* 最終清晰 */
        opacity: 1;         /* 最終完全可見 */
    }
}

@keyframes fadeInUp {
    0% {
    opacity: 0;
    transform: translateY(80px);
    }
    100% {
    opacity: 1;
    transform: translateY(0);
    }
}

.circle-overlay {
    position: fixed; // 确保覆盖整个屏幕
    background-color: #DFDED9; // 圆的颜色，确保和背景形成对比
    z-index: 1000; // 确保在最上方，覆盖所有内容
    transform: translate(-50%, -50%); // 确保圆形以中心为基准放大
    pointer-events: none; // 禁止对圆形的交互
}

.next-projects {
    margin-bottom: 80px;
}

.ad {
    border: 1px solid $gray-3;
    border-radius: 3px;
    background-color: $bg-2;
    padding: 16px 20px;
    min-height: 170px;
    &-topic {
        @include flex(flex-start, center);
        margin-bottom: 8px;
        h4 {
            margin-left: 12px;
        }
    }
    &-column1 {
        @include grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
        // grid-template-columns: 1fr 1fr 1fr;
        color: $gold;
        gap: 24px;
    }
    h5 {
        color: $gray-2;
    }
}

.project {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 1px solid #2B2B2B;
    border-radius: 4px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease; // 平滑過渡效果
    &-info {
        @include flex(center, flex-start);
        flex-direction: column;
        color: $gray-2;
        background-color: $bg-2;
        // backdrop-filter: blur(3px);
        height:80px;
        padding: 10px 24px;
        span {
            font-size: var(--project-title-size);
            font-variation-settings: var(--h4-font-weight);
            color: $gold;
        }
    }
    &-container {
        @include grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 24px;
        row-gap: 32px;
        margin-top: 40px;
        &_info {
            cursor: default;
            @include flex(center, flex-start);
            flex-direction: column;
            max-width: 1440px;
            padding-top: 100px;
            padding-left: 48px;
            padding-right: 48px;
            text-align: left;
            margin: auto;
        }
    }
    img {
        width: 100%;
    }
    &-effect {
        position: relative; // 保證陰影偽元素能相對定位
        transition: transform 0.3s ease; // 控制浮起效果的平滑過渡
        &:hover {
            transform: translateY(-10px) rotateX(-8deg) rotateY(8deg) scale(1);
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background-color: #2b2b2b; // 模擬陰影的顏色
            z-index: -1; // 保證陰影在元素後面
            transition: transform 0.3s ease; // 讓陰影效果也有平滑過渡
            transform: translate(0px, 0px) scale(1); // 初始陰影位置，模擬厚度
        }
        &:hover::after {
            transform: translate(4px, 4px) scale(1.01); // 模擬厚度增加的效果
            box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.5); 
    
        }
    }
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


.footer {
    opacity: 0;
    max-width: 1440px;
    margin: auto;
    color: $gray-2;
    letter-spacing: 0.5px;
    text-align: center;
    padding: 40px 48px 24px 48px;
    flex-shrink: 0; // 確保 footer 不會被壓縮
    p {
        margin-bottom: 8px;
        font-size: var(--project-title-size);
        font-variation-settings: var(--h4-font-weight);
    }
    &-container {
        @include flex;
        column-gap: 12px
    }
}


@import "./media.scss";