@media screen and (max-width: 840px) {
    .banner {
        margin-bottom: 40px;
        // text-align: center;
    }
    .spotlight-container {
        p {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h1 {
        .zh-style {
            --h1-font-size: 56px;
        }
        // font-size: 56px;
        // line-height: 64px;
    }
    .ad {
        min-height: 130px;
        &-column1 {
            grid-template-columns: 1fr;
            row-gap: 20px;
        }
    }
    .project {
        &-container {
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }
    }
}

@media screen and (max-width: 660px) {
    .project {
        &-container {
            grid-template-columns: 1fr;
            row-gap: 24px;
        }
    }
}

@media screen and (max-width: 588px) {
    .footer {
        &-container {
            .line {
                display: none;
            }
            flex-direction: column;
        }
    }
    .card {
        &-title {
            line-height: 64px;
            margin: 12px 0 20px 0;
        }
    }
}

@media screen and (max-width: 480px) {
    .spotlight-container {
        padding: 132px 20px 0px 20px;
    }
    .header {
        padding: 12px 20px 12px 20px;
    }
    .project-container_info {
        padding: 80px 20px 0px 20px;
    }
}

@media screen and (max-width: 428px) {
    .card {
        &:first-child {
            grid-column: span 1;
        }
        &-group {
            grid-template-columns: 1fr;
        }
    }
    .zh-style {
        --h1-font-size: 56px;
        --h2-font-size: 20px;
        --h2-line-height: 28px;
    }
    .en-style {
        --h1-font-size: 44px;
        --h2-font-size: 20px;
        --h2-line-height: 24px;
    }
}