.en-style {
    --font-family: "adelphi-pe-variable", sans-serif;
    --h1-font-size: 68px;
    --h2-font-size: 24px;
    --h4-font-size: 18px;
    --h5-font-size: 16px;
    --project-title-size: 14px;
    --h2-line-height: 32px;
    --h1-font-weight: "opsz" 12, "slnt" 0, "wght" 800;
    --h2-font-weight: "opsz" 6, "slnt" 0, "wght" 100;
    --h4-font-weight: "opsz" 6, "slnt" 0, "wght" 400;
    --h5-font-weight: "opsz" 6, "slnt" 0, "wght" 200;
    --h6-font-weight: "opsz" 6, "slnt" 0, "wght" 300;
}
.zh-style {
    --font-family: 'Noto Sans TC', sans-serif;
    --h1-font-size: 64px;
    --h2-font-size: 22px;
    --h4-font-size: 18px;
    --h5-font-size: 16px;
    --project-title-size: 14px;
    --h2-font-weight: "wght" 400;
    --h4-font-weight: "wght" 400;
    --h5-font-weight: "wght" 400;
    --h2-line-height: 36px;
    --h1-font-weight: "wght" 600;
}
